export const String = {
  orders: 'orders',
  TSh: 'TSh',
  days: 'days',
  unit: 'Units',
  products: 'Products',
  pleaseWait: 'Please wait...',
  DeleteCommonText: 'Are you sure you want to remove ',
  countryCode: '+255',
  warehouse: 'warehouse',
  ordersDelivered: 'orders delivered',
  singleOrderDelivered: 'order delivered',
  order: 'order',
  singleUnit: 'Unit',
  EnterName: 'Enter name...',
  EnterPhone: 'Enter phone number...',
  EnterEmail: 'Enter email...',
  EnterVehicle: 'Enter vehicle number....',
};
export const Error = {
  NET_ERR: 'Please check your internet connection and try again!',
};
export const SidebarTitle = {
  Dashboard: 'Dashboard',
  Customer: 'Customers',
  AllCustomer: 'All Customer',
  CustomerRefund: 'Refunds to Customer',
  Master: 'Master',
  Category: 'Categories',
  Brands: 'Brands',
  ProductVariants: 'Product Variants',
  Roles: 'Roles & Permissions',
  User: 'User Management',
  Delivery: 'Delivery Users',
  PlatformFee: 'Platform fee & taxes',
  ContactInquires: 'Contact Enquiries',
  AboutUs: 'About Us',
  Privacy: 'Privacy Policy',
  RefundPolicy: 'Refund Policy',
  Terms: 'Terms & Conditions',
  Settings: 'Settings',
  generalSettings: 'General Settings',
  CmsPage: 'CMS Pages',
  faqs: `FAQ's`,
  LoadingArea: 'Warehouse Products Zone',
  BannerManagement: 'Banner Management',
  AppSettings: 'App Settings',
  CustomNotification: 'Custom Notification',
  ProductZone: 'Product Zone',
};
export const Authentication = {
  Login: 'Please sign in to your admin account!',
  ForgetPassword: 'Forgot password?',
  ForgetText:
    ' Please enter your registered email address to receive an OTP for resetting your password.',
  Verify: 'Verify your account',
  VerifyText: ' We’ve sent an OTP to admin@uMart.com',
  VText: 'Please enter it below to reset the password.',
  ResendOtp: 'Resend OTP in',
  GoBack: 'Go back to',
  CreatePassword: 'Create new password',
  CreateText: ' Please set a new password for your account.',
  CreatePasswordButton: ' Set password & Sign in',
  SignIn: 'Sign in',
  Submit: 'Submit',
};
export const InventoryString = {
  goodsTitle: 'Goods In Warehouse',
  goodsTitleAdd: 'Add goods to inventory',
  addButton: 'Add goods to inventory',
  numLoading: 'Number of loading areas',
  numCategory: 'Number of category',
  productsAvailable: 'Products available',
  unitsAvailable: 'Units available',
  filterCategory: 'Filter by category',
  filterProduct: 'Filter by product status',
  filterExpiry: 'Filter by expiry period',
  filterProductName: 'Filter by product name',
  tableHeadingProduct: 'Product name',
  tableHeadingStock: 'Expire stock',
  tableHeadingNotify: 'notify before',
  filterWarehouseName: 'Warehouse name',
  filterStockLastFor: 'Filter by stock last for',
  addedText:
    'This product is added successfully to the inventory. You can manage the product.',
  addGoodsToLowStock: 'Add goods to low stock list',
};
export const AllProduct = {
  allProduct: 'All products',
  startAdding: 'Start adding products!',
  productBody: 'You can add different products for different categories.',
  newProduct: ' Add new products',
  newProduct2: ' View all products',
  saveDraft: ' Save as draft',
  titleProductModal: 'Product image guidelines',
  titleBody:
    'Please follow image guidelines to reduce the quality check failures',
  point1: 'Use clear color images with minimum resolution of 1000px X 1000px',
  point2: 'Upload authentic product photos taken in bright lighting.',
  point3:
    'Check out the sample images to ensure you provide the correct image view. e.g. First Image has to be the Front view.',
  sampleImage: 'Sample images',
};
export const CategoryString = {
  addTitle: 'Add sub category',
  title: 'Categories',
  addNew: 'Add new category',
  editPrimary: 'Edit primary category',
  editSubCategory: 'Edit sub category',
  editGroupCategory: 'Edit group category',
  categoryName: 'Sub category name',
  subCategory: 'Sub category ',
  groupCategoryName: 'Group category name',
  primaryCategory: 'Primary category',
  primaryCategoryName: 'Primary category name',
  uploadImage: 'Upload category image',
  imageNote1: 'Note: Please upload a high resolution PNG image',
  imageNote2: '(No background) for the category image.',
  addNewButton: '+ Add new category',
  cancel: 'Cancel',
  save: 'Save category',
  saveChanges: 'Save changes',
  allCategory: 'All categories',
  deleteFlag1:
    'There is no product in accessories category. So you can delete this category.',
  deleteFlag2:
    'There are 20 products listed in this category. So you cannot delete this category. You can only delete the category when no product is listed there.',
  startAdding: 'Start adding categories!',
  bodyTitle:
    'You can add primary categories, sub categories and group categories.',
  addPrimaryCategory: 'Add primary category',
  addGroupCategory: 'Add group category',
  addSubCategory: 'Add sub category',
  editDetails: 'Edit category',
  deleteCategory: 'Delete category',
};
export const brandsString = {
  brands: 'Brands',
  addNewBrand: 'Add new brand',
  more: '+ Add new brand',
  startAdding: 'Start adding brands!',
  manageBrands: 'You can add brands and manage them.',
  addBrand: 'Add brand',
  editBrands: 'Edit brand',
  deleteBrands: 'Delete brand',
  brandName: ' Brand name',
  upload: 'Upload',
  brandLogo: 'brand logo',
  cancel: 'Cancel',
  saveChanges: 'Save Changes',
  saveBrand: 'Save brand',
};
export const productString = {
  productAdded:
    'This product is added successfully. You can manage your product anytime.',
  productDeleted: 'Product Deleted successfully!!',
  activated: 'Product activated successfully!!',
  deactivated: 'Product deactivated successfully!!',
};
export const productVariantsString = {
  productVariants: 'Product variants',
  addNewVariant: ' Add new variant',
  startAdding: 'Start adding variants!',
  startAdding2: 'You can add different variants for the products.',
  addVariant: 'Add variant',
  variantName: 'Variant name',
  linkedCategories: 'Linked categories',
  categories: 'Categories',
  linkMessage: 'Link this variant to categories',
  category: 'Category',
  textGuide: 'Text guide / Suggest options',
  typeOption: 'Type option & press tab / enter',
  cancel: 'Cancel',
  save: 'Save variant',
  noCategories: 'No categories available',
  pleaseAdd: 'Please Add Categories to link variant.',
  editCategoryVariants: 'Edit this variant',
  deleteCategoryVariants: 'Remove this variant',
  editVariantsCategory: 'Edit this category',
  deleteVariantsCategory: 'Delete this category',
};
export const DeliverString = {
  title: 'Delivery Users',
  addNew: 'Add new delivery user',
  saveUser: 'Save Changes',
  addNewButton: 'Add delivery users',
  addUser: 'Add delivery user',
  vehicleNumber: 'Vehicle Number',
  editDetails: 'Edit details',
  editDeliveryUser: 'Edit delivery user',
  startAddingTitle: 'Start adding delivery users!',
  manageRoleBody: 'You can add delivery users and manage them.',
  name: 'Name',
  phoneNumber: 'Phone number',
  activate: 'Activate',
  deactivate: 'Deactivate',
  editUser: 'Edit delivery user profile',
  delete: 'Delete delivery user',
  viewDetails: 'View Details',
  deliveryProfile: 'Delivery user profile',
  totalDeliveries: 'Total deliveries',
  assignedWarehouse: 'Assigned warehouses',
  basicDetails: 'Basic details',
  email: 'Email',
  dob: 'DOB',
  address: 'Address',
  warehouse: 'Associated Warehouse',
  productImages: 'Product images',
  dateTime: 'Delivery date & time',
  orderId: 'Order ID',
  amountCollected: 'Amount collected',
  documents: 'Documents',
  uploadPhoto: 'Upload photo',
  vehicleUpload: 'Vehicle document',
  drivingLicence: 'Driving license',
  vehicleInsurance: 'Vehicle insurance',
  typeHere: 'Type here…',
  uploadQRCode: 'Upload QR code image',
};
export const OrdersDelivery = {
  pageTitle: 'Orders & Delivery',
  searchInput: 'Search by order ID, customer name…',
  searchTitle: 'Search orders',
  filterSelect: 'Filter by delivery user',
  filterDate: ' Filter by dates',
  headingOrder: 'Order Date & ID',
  headingCustomer: 'Customer name',
  headingDelivery: 'Assigned delivery user',
  headingCancelBy: 'Cancelled by',
  headingUnits: 'Total units',
  headingAmount: 'Total amount',
  tabNewOrder: 'Order placed',
  tabDeliveries: 'Deliveries',
  tabProgress: 'Ready for dispatch',
  tabDelivery: 'Out for delivery',
  tabDelivered: 'Delivered',
  tabCancelled: 'Cancelled',
  orderDetails: 'Order details',
  downloadInvoice: 'Download invoice',
  basicDetails: 'Basic details',
  customerName: 'Customer name',
  orderId: 'Order ID',
  orderPlaced: 'Order placed on',
  paymentMethod: 'Payment method',
  billingAdd: 'Billing address',
  deliveryUser: 'Delivery user',
  phoneNum: 'Phone number',
  deliverBy: 'Deliver by',
  deliverOn: 'Delivered on',
  shippingAdd: 'Shipping address',
  distCity: 'District & city',
  deliveryDetails: 'Delivery details',
  productName: 'Product name',
  unitPrice: 'Unit price',
  qtyPrice: 'Total Qty',
  tax: 'Tax(%)',
  totalAmount: 'Total amount',
  taxAmount: 'Tax amount',
  sku: 'SKU',
  subTotal: 'Subtotal (inclusive of all taxes)',
  platformFee: 'Platform fees',
  totalTax: 'Tax',
  deliveryCharge: 'Delivery Charges',
  grandTotal: 'Grand Total',
  modalDriver: 'Yes, Assign',
  headingDate: 'Date',
  headingTotalOrders: 'Total orders',
  headingProducts: 'Total products',
  headingTotalUser: 'Total delivery user',
  headingStatus: 'Status',
  deliveriesOf: 'Deliveries of',
  cardTotalDelivery: 'Total delivery users',
  cardTotalAssigned: 'Total assigned orders',
  cardTotalProducts: 'Total products',
  cardTotalCash: 'Total cash collection',
  status: 'Status',
  user: 'users',
  singleUser: 'user',
  singleOrder: 'order',
  order: 'orders',
  product: 'products',
  singleProduct: 'product',
  viewDetails: 'View details',
  assignedOrders: 'Assigned orders',
  totalGoodsWeight: 'Total goods weight',
  tons: 'tons',
  packed: 'packed',
  noData: 'No data',
  goodsLoadingDetails: 'Goods loading details',
  vehicleNumber: 'Vehicle number',
  deliveryUserName: 'Delivery user name',
  palacedOn: 'Placed on',
  batch: 'Batch',
  notPackLabel: 'Not packed',
  packedLabel: 'Packed',
  goods: 'Goods',
  loadingArea: 'loading area',
  quantityAndBatch: 'Quantity & batch',
  markDispatch: 'Mark as ready for dispatch',
  orderPreparing: 'Preparing Order',
  deliveredOn: 'Delivered On',
  instant:'Instant'
};
export const Customers = {
  customerName: 'Customer name',
  totalOrders: 'Total orders',
  totalDeliveredOrders: 'Total delivered orders',
  totalValue: 'Total value',
  registerOn: 'Registered on',
  activateDeactivate: 'Activate / Deactivate',
  search: 'Search by customer name…',
  viewDetails: 'View details',
  noData: '  No Data found',
  addNew: 'Add new customer',
  titleCustomer: 'Customers',
  averageOrder: 'Avg Order',
  averageOrderVal: 'Avg Order Value',
  totalOrderVal: 'Total order value',
  totalCancel: 'Total cancelled orders',
  totalProduct: 'Total products bought',
  totalProductSold: 'Total products sold',
  orderDate: 'Order Date & ID',
  totalUnits: 'Total units',
  modePayment: 'Mode of payment',
  recentOrder: 'Recent orders',
  viewAll: 'View all',
  basicDetails: 'Basic details',
  name: 'Name:',
  number: 'Phone number:',
  mail: 'Gmail:',
  register: 'Registered on:',
  deliveryAddress: 'Customer addresses',
  badgeHome: 'Home',
  badgeWork: 'Work',
  salesReport: 'Sales report',
  searchOrder: 'Search by order ID…',
  customerProfile: 'Customer profile',
  badgeOther: 'Other',
  orderRefunds: 'Order Refunds',
  returnRequestedOn: 'Return requested on',
  walletHistory: 'Wallet history',
  dateAndTime: 'Date & time',
  numberOfCoin: 'Number of coins',
  transactionThrough: 'Transaction through',
  walletCoins: 'Wallet coins',
  ratings: 'Ratings',
};
export const faqString = {
  faq: 'FAQs',
  search: 'Search...',
  question: 'Question',
  answer: 'Answer',
  addMoreFaq: 'Add more FAQs',
  edit: 'Edit FAQ',
  delete: 'Delete FAQ',
  modalTitle: 'Frequently asked question!',
  save: 'Save changes',
};
export const cmsString = {
  aboutUsTitle: 'Add / Edit About Us',
  termsTitle: 'Add / Edit Terms And Conditions',
  privacyTitle: 'Add / Edit Privacy Policy',
  refundTitle: 'Add / Edit Refund Policy',
};
export const SettingsStrings = {
  saveDetails: 'Save details',
  fixed: 'Fixed',
  platformFee: 'Platform fees',
  setPlatformFee: 'Set platform fees',
  platformBody:
    'You can set a fixed platform fee for the customers using platform.',
  perKm: 'Per km',
  deliveryCharges: 'Delivery charges',
  deliveryBody:
    'You can set a delivery charges per kilometer/ mile for the customers.',
  setDeliveryCharges: 'Set delivery charges',
  setMinimum: 'Set minimum',
  orderVal: 'Order value',
  minOrderBody: 'You can set a minimum order value for the buyers.',
  setMinOrder: 'Set Minimum order value',
  generalSettings: 'General Settings',
  setCoinValue: 'Set coin value',
  coinValueText:
    'You can customize the value of virtual coins for customer wallet transactions.',
  coinValue: 'Coin value',
  perOneTsh: 'Per 1 TSh',
};
export const ContactString = {
  title: 'Contact Enquiries',
  searchPlaceholder: 'Search by customer name…',
  viewProfile: 'View profile',
  delete: 'Delete this enquiry',
  viewMessage: 'View message',
  name: 'Name',
  receivedOn: 'Received on',
};
export const UserString = {
  title: 'User Management',
  startAddingTitle: 'Start adding users!',
  manageRoleBody: 'You can add users and manage them.',
  addUser: 'Add user',
  addNewUsers: 'Add new users',
  addNewUser: 'Add new user',
  editUser: 'Edit user',
  typeHere: 'Type here…',
  cancel: 'Cancel',
  name: 'Name',
  phoneNumber: 'Phone number',
  userRole: 'User role',
  activate: 'Activate',
  deactivate: 'Deactivate',
  search: 'Search by user name...',
  edit: 'Edit user',
  delete: 'Delete user',
  email: 'Email',
  assignRole: 'Assign role',
  uploadPhoto: 'Upload photo',
  saveUser: 'Save details',
};
export const RolesString = {
  title: 'Roles & Permissions',
  startAddingTitle: 'Start adding roles!',
  manageRoleBody: 'You can add role and manage them.',
  addRole: 'Add role',
  moduleName: 'Module name',
  permission: 'Permission',
  namePlaceholder: 'Enter role name',
  addNewRole: 'Add new role',
  roleName: 'Role name',
  setPermission: 'Set permissions',
  customer: 'Customer',
  ordersDelivery: 'Orders & delivery',
  allProduct: 'All products',
  inventory: 'Inventory',
  master: 'Master',
  cancel: 'Cancel',
  editRole: 'Edit role',
  deleteRole: 'Delete role',
  saveChanges: 'Save Changes',
  yesDelete: 'Yes, delete',
};
export const DashboardString = {
  dashBoardTitle: 'Dashboard',
  totalWareHouses: 'Total warehouses',
  totalOrders: 'Total orders',
  totalDeliveredOrders: 'Total delivered orders',
  totalCustomers: 'Total customers',
  totalProduct: 'Total products',
  activeCustomers: 'Active Customers',
  orderReports: 'Orders report',
  orderSameDay: 'Orders delivered same day',
  orderNextDay: 'Orders delivered next day',
  orderAfterTwoDays: 'Orders delivered after 2 days',
  orders: 'Orders',
  totalSales: 'Total Sales',
};
export const GoodsRequestString = {
  addGoodsRequest: 'Add goods request',
  prductAndQuantiry: 'Products & quantities',
  selectProduct: 'Select product',
  quantity: 'Quantity',
  expectedDeliveryDate: 'Expected delivery date',
  goodsDetails: 'Goods details',
  goodsRequests: 'Goods Requests',
  pendingText: 'Pending ',
  completedText: 'Completed',
  canceledText: 'Cancelled',
  warehouseName: 'Warehouse name',
  requestInitiateOn: 'Request initiated on',
  requestInitiateBy: 'Request initiated by',
  requestCompletedOn: 'Request completed on',
  requestCancelledOn: 'Request cancelled on',
  goodsRequestDetails: 'Goods requests details',
  addGoodsToInventory: 'Add goods to inventory',
  requestId: 'Request ID',
  totalUnits: 'Total units',
  dateOfCompletion: 'Date of completion',
  dateOfCancellation: 'Date of cancellation',
  editGoodsRequest: 'Edit goods request',
  requestedBy: 'Requested by',
};
export const ReturnRequestString = {
  returnRequests: 'Return Requests',
  newRequest: 'New request',
  collected: 'Collected',
  arrivedAtWarehouse: 'Arrived at warehouse',
  refunded: 'Refunded',
  orderAndId: 'Order Date & ID',
  returnIniatedOn: 'Return initiate on',
  assignDeliveryUser: 'Assigned delivery User',
  returnRequestedFor: 'Return requested for',
  collectedOn: 'Collected on',
  arrivedAt: 'Arrived at',
  refundProcessOn: 'Refund processed On',
  returnedItems: 'Returned items',
  returnRequestDetails: 'Return request details',
  orderRefundDetails: 'Order refund details',
  customerName: 'Customer name:',
  totalItems: 'Total items:',
  requestId: 'Request ID:',
  orderDate: 'Order date:',
  requestInitiateOn: 'Request initiated on:',
  pickupAddress: 'Pickup address',
  batchAndExpiry: 'Batch & Expiry',
  untis: 'Units',
  markAsArrived: 'Mark as arrived',
  initateRefund: 'Initiate refund',
  totalUnits: 'Total units',
  totalPrice: 'Total price',
  deliveryUser: 'Delivery user',
  totalRefundableAmount: 'Total refundable amount',
  coinExchange: 'Coins in exchange',
  markAsRefunded: 'Mark as refunded',
  refundedAmount: 'Refunded amount',
};
export const PromotionAndCampaignString = {
  addPromotion: 'Add promotion',
  discountPromotion: 'Discount promotion',
  couponPromotion: 'Coupon promotion',
  rewards: 'Rewards',
  filterByPromotion: 'Filter by promotion type',
  filterByEndDates: 'Filter by end dates',
  promotionBanner: 'Promotion banner',
  promotionTitle: 'Promotion title',
  promotionType: 'Promotion type',
  endDate: 'End date',
  startDate: 'Start date',
  deactivate: 'Deactivate',
  activate: 'Activate',
  actions: 'Actions',
  addDiscountPromotions: 'Add discount promotion',
  EditDiscountPromotions: 'Edit discount promotion',
  discountType: 'Discount Type',
  discountTitle: 'Discount title',
  discountValue: 'Discount value',
  minPurchaseAmount: 'Minimum purchase amount',
  description: 'Description',
  flatDiscount: 'Flat discount',
  percentageDiscount: 'Percentage discount',
  promotionImage: 'Promotion image',
  promotionImageNote: 'Note: Please upload a high resolution JPG or PNG image',
  discountPromotionDetails: 'Discount promotion details',
  addReward: 'Add reward',
  rewardType: 'Reward type',
  rewardTitle: 'Reward title',
  rewardValue: 'Reward value',
  redemptionLimit: 'Redemption limit',
  coins: 'Coins',
  editReward: 'Edit reward',
  rewardDetails: 'Reward details',
  rewardEarnedOn: 'Rewarded earned on',
  redemptionUses: 'Redemption Uses',
  addCouponPromotion: 'Add coupon promotion',
  couponType: 'Coupon type',
  couponTitle: 'Coupon title',
  couponCode: 'Coupon code',
  applyCouponThrough: 'Coupon apply through',
  applyCouponTo: 'Apply coupon to',
  editCouponPromotion: 'Edit coupon promotion',
  couponPromotionDetailsPage: 'Coupon promotion details',
  totalEarnedCoins: 'Total earned coins',
};
export const BannerString = {
  bannerManagement: 'Banner Management',
  addNewBanner: 'Add new banner',
  downloadReport: 'Download report',
  banner: 'Banner',
  impression: 'Impression',
  clicks: 'Clicks',
  activeDeactive: 'Activate / Deactivate',
  addBanner: 'Add banner',
  bannerName: 'Banner name ',
  bannerImage: 'Banner image',
  bannerImageNote: 'Note: Please upload a high resolution JPG image',
  bannerPlaceMent: 'Banner placement',
  bannerType: 'Banner Type',
  bannerSelection: 'Product selection',
  productForBanner: 'Select products for banner *',
  publicBanner: 'Publish banner',
  bannerDetails: 'Banner details',
  editBanner: 'Edit banner',
  deleteBanner: 'Delete banner',
  showBannerFor: 'Show banner for ',
  startDate: 'Start date',
  endDate: 'End date',
};
export const LowStocksString = {
  title: 'Low Stock List',
  orderedThisProduct: 'I ordered this product',
  modalText: 'Are you sure you`ve ordered this product',
};
export const CustomNotificationString = {
  cutomNofication: 'Custom Notification',
  title: 'Title',
  description: 'Description',
  selectCustomer: 'Select Customers',
};

import clsx from 'clsx';
import { toAbsoluteUrl } from '../../../helpers';
import { HeaderNotificationsMenu, HeaderUserMenu } from '../../../partials';
// import { useLayout } from '../../core'
// Media
// import NotificationSVG from "../../../../umart_admin/assets/media/svg_uMart/bell-icon.svg";
import { useAuth } from '../../../../app/modules/auth';
const itemClass = 'ms-1 ms-lg-3';
const btnClass = 'btn btn-icon btn-custom btn-icon-muted';
const userAvatarClass =
  'symbol-40px symbol-md-45px symbol-lg-50px symbol-circle';
// const btnIconClass = 'svg-icon-1'
const Navbar = () => {
  // const { config } = useLayout()
  const { currentUser } = useAuth();
  return (
    <div className="app-navbar flex-shrink-0">
      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          className={btnClass}
        >
          <img className="img-fluid" src={NotificationSVG} alt="" />
        </div>
        <HeaderNotificationsMenu />
      </div> */}
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
        >
          <div className="symbol  symbol-50px symbol-circle ">
            <div className="symbol-label fs-1 fw-bold bg-light-primary text-primary fw-700">
              {currentUser?.name[0] || 'A'}
            </div>
          </div>
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  );
};
export { Navbar };

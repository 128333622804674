import { FC, Suspense, lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { MasterLayout } from '../../umart_admin/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import { getCSSVariableValue } from '../../umart_admin/assets/ts/_utils';
import { WithChildren } from '../../umart_admin/helpers';
import InventoryPage from '../modules/Inventory/index';
import Products from '../modules/all-products/index';
import Orders from '../modules/orders-delivery/index';
import Customers from '../modules/customers/index';
import ContactEnquiries from '../modules/contact-enquiries/index';
import CustomNotification from '../modules/custom-notification/index';
import ReturnRequests from '../modules/return-requests/index';
const SalesReport = lazy(() => import('../modules/sales-report'));
const MasterPage = lazy(() => import('../modules/master/index'));
const CMSPage = lazy(() => import('../modules/cms-pages/index'));
const SettingsPage = lazy(() => import('../modules/settings/index'));
const GoodsRequests = lazy(() => import('../modules/goods-requests/index'));
const FinanceReports = lazy(() => import('../modules/Finance-Reports'));
const PromotionAndCampaign = lazy(
  () => import('../modules/promotion-campaign/index')
);
const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route
          path="auth/*"
          element={<Navigate to="/dashboard" />}
        />
        {/* Pages */}
        <Route
          path="dashboard"
          element={<DashboardWrapper />}
        />
        <Route
          path="inventory/*"
          element={
            <SuspensedView>
              <InventoryPage />
            </SuspensedView>
          }
        />
        <Route
          path="promotion-campaign/*"
          element={
            <SuspensedView>
              <PromotionAndCampaign />
            </SuspensedView>
          }
        />
        <Route
          path="finance-reports/*"
          element={
            <SuspensedView>
              <FinanceReports />
            </SuspensedView>
          }
        />
        <Route
          path="all-products/*"
          element={
            <SuspensedView>
              <Products />
            </SuspensedView>
          }
        />
        <Route
          path="goods-requests/*"
          element={
            <SuspensedView>
              <GoodsRequests />
            </SuspensedView>
          }
        />
        <Route
          path="all-return-requests/*"
          element={
            <SuspensedView>
              <ReturnRequests />
            </SuspensedView>
          }
        />
        <Route
          path="orders/*"
          element={
            <SuspensedView>
              <Orders />
            </SuspensedView>
          }
        />
        <Route
          path="sales-report/*"
          element={
            <SuspensedView>
              <SalesReport />
            </SuspensedView>
          }
        />
        <Route
          path="customers/*"
          element={
            <SuspensedView>
              <Customers />
            </SuspensedView>
          }
        />
        {/* Lazy Modules */}
        <Route
          path="master/*"
          element={
            <SuspensedView>
              <MasterPage />
            </SuspensedView>
          }
        />
        <Route
          path="cms-pages/*"
          element={
            <SuspensedView>
              <CMSPage />
            </SuspensedView>
          }
        />
        <Route
          path="settings/*"
          element={
            <SuspensedView>
              <SettingsPage />
            </SuspensedView>
          }
        />
        <Route
          path="custom-notifications/*"
          element={
            <SuspensedView>
              <CustomNotification />
            </SuspensedView>
          }
        />
        <Route
          path="contact-enquiries/*"
          element={
            <SuspensedView>
              <ContactEnquiries />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route
          path="*"
          element={<Navigate to="/error/404" />}
        />
      </Route>
    </Routes>
  );
};
const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};
export { PrivateRoutes };

import { Button, Card, Col, Row } from 'react-bootstrap';
import { CustomNotificationString } from '../../../utils/string';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { error, success } from '../../../Global/toast';
import APICallService from '../../../api/apiCallService';
import {
  buyer,
  dashBoardEndPoints,
  manageProductInventory,
} from '../../../api/apiEndPoints';
import { notificationJSON } from '../../../utils/staticJSON';
import { CustomSelectWhite } from '../../custom/Select/CustomSelectWhite';
import { CustomReportSelect } from '../../custom/Select/CustomReportSelect';
import { customNotificationsToast } from '../../../utils/toast';
import { Add, CustomNotificationConst } from '../../../utils/constants';
import Method from '../../../utils/methods';
import { useAuth } from '../auth';
const CustomNotification = () => {
  const { currentUser } = useAuth();
  const [productLoading, setProductLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [notificationData, setNotificationData] = useState<{
    title: string;
    message: string;
    buyers: any;
    variants: any;
  }>({
    title: '',
    message: '',
    buyers: [],
    variants: [],
  });
  const [validation, setValidation] = useState<{
    title: boolean;
    message: boolean;
    buyers: boolean;
    variants: boolean;
  }>({
    title: false,
    message: false,
    buyers: false,
    variants: false,
  });
  const [customerList, setCustomerList] = useState<any>([]);
  const [productsList, setProductsList] = useState<any>([]);
  const [selectedNotification, setSelectedNotification] = useState<any>(null);
  useEffect(() => {
    (async () => {
      if (!Method.hasModulePermission(CustomNotificationConst, currentUser)) {
        return window.history.back();
      }
      await fetchCustomers();
    })();
  }, []);
  const fetchCustomers = async () => {
    setCustomerLoading(true);
    let params = {
      sortKey: 'createdAt',
      sortOrder: -1,
      state: 1,
      needCount: false,
    };
    const apiService = new APICallService(
      buyer.customerList,
      params,
      '',
      '',
      false,
      '',
      CustomNotificationConst
    );
    const response = await apiService.callAPI();
    if (response) {
      let data: any = [];
      response.records.map((val: any) => {
        data.push({
          value: val._id,
          name: val.name,
          title: val.name,
          label: (
            <>
              <span className="symbol symbol-xl-40px symbol-35px border border-r10px me-2">
                <img
                  src={val.image}
                  className="p-1"
                />
              </span>
              <span className="fs-16 fw-600 text-black ">{val.name}</span>
            </>
          ),
          id: val._id,
        });
      });
      data.unshift({
        value: 0,
        name: 'Select All',
        title: 'Select All',
        label: (
          <>
            <span className="fs-16 fw-600 text-black ">Select All</span>
          </>
        ),
        id: 0,
      });
      setCustomerList(data);
    }
    setCustomerLoading(false);
  };
  const fetchProducts = async () => {
    setProductLoading(true);
    let data = {
      sortKey: 'title',
      sortOrder: -1,
      state: 1,
      needCount: false,
      // ['status[0]']: ApprovedProduct,
    };
    let apiService = new APICallService(
      manageProductInventory.listProduct,
      data,
      '',
      '',
      false,
      '',
      CustomNotificationConst
    );
    let response = await apiService.callAPI();
    if (response.records) {
      let data: any = [];
      response.records.map((val: any) => {
        data.push({
          value: val._id,
          name: val.title,
          title: val.title,
          label: (
            <>
              <span className="symbol symbol-xl-40px symbol-35px border border-r10px me-2">
                <img
                  src={val.media[0].url}
                  className="p-1"
                />
              </span>
              <span className="fs-16 fw-600 text-black ">{val.title}</span>
            </>
          ),
          id: val?._id,
          productId: val?.product?.reference,
        });
      });
      setProductsList(data);
    } else {
      setProductsList([]);
    }
    setProductLoading(false);
  };
  const fetchTopProducts = async () => {
    let params: any = {
      needTopProducts: true,
    };
    let apiService = new APICallService(
      dashBoardEndPoints.getInitData,
      params,
      '',
      '',
      false,
      '',
      CustomNotificationConst
    );
    let response = await apiService.callAPI();
    if (response && response.topProducts.records.length) {
      let data: any = [];
      response.topProducts.records.map((val: any) => {
        data.push({
          value: val._id,
          name: val.title,
          title: val.title,
          label: (
            <>
              <span className="symbol symbol-xl-40px symbol-35px border border-r10px me-2">
                <img
                  src={val?.media[0]?.url || ''}
                  className="p-1"
                />
              </span>
              <span className="fs-16 fw-600 text-black ">{val.title}</span>
            </>
          ),
          id: val._id,
          productId: val?.product?.reference,
        });
      });
      setProductsList(data);
    } else {
      error('No products are available in the cart');
      setProductsList([]);
    }
  };
  const fetchTopCustomers = async () => {
    let params: any = {
      needTopCustomers: true,
    };
    let apiService = new APICallService(
      dashBoardEndPoints.getInitData,
      params,
      '',
      '',
      false,
      '',
      CustomNotificationConst
    );
    let response = await apiService.callAPI();
    if (response && response.topCustomers.length) {
      let data: any = [];
      let selectAll: any = [
        {
          value: 0,
          name: 'Select All',
          title: 'Select All',
          label: (
            <>
              <span className="fs-16 fw-600 text-black ">Select All</span>
            </>
          ),
          id: 0,
        },
      ];
      response.topCustomers.map((val: any) => {
        data.push({
          value: val._id,
          name: val.name,
          title: val.name,
          label: (
            <>
              <span className="symbol symbol-xl-40px symbol-35px border border-r10px me-2">
                <img
                  src={val.image}
                  className="p-1"
                />
              </span>
              <span className="fs-16 fw-600 text-black ">{val.name}</span>
            </>
          ),
          id: val._id,
        });
      });
      data.unshift(...selectAll);
      setCustomerList(data);
    } else {
      error('No top customers in last month');
      setProductsList([]);
    }
  };
  const fetchBuyerCart = async (customerId: string) => {
    setProductLoading(true);
    let apiService = new APICallService(
      buyer.getBuyerCart,
      customerId,
      '',
      '',
      false,
      '',
      CustomNotificationConst
    );
    let response = await apiService.callAPI();
    if (response.records.length) {
      let data: any = [];
      let selectAll: any = [
        {
          value: 0,
          name: 'Select All',
          title: 'Select All',
          label: (
            <>
              <span className="fs-16 fw-600 text-black ">Select All</span>
            </>
          ),
          id: 0,
        },
      ];
      response.records.map((val: any) => {
        data.push({
          value: val?.reference._id,
          name: val?.reference.title,
          title: val?.reference.title,
          label: (
            <>
              <span className="symbol symbol-xl-40px symbol-35px border border-r10px me-2">
                <img
                  src={val?.reference.media[0].image}
                  className="p-1"
                />
              </span>
              <span className="fs-16 fw-600 text-black ">
                {val?.reference.title}
              </span>
            </>
          ),
          id: val?.reference._id,
          productId: val?.reference.product?.reference,
        });
      });
      data.unshift(...selectAll);
      setProductsList(data);
    } else {
      // error('No products are available in the cart');
      setProductsList([]);
    }
    setProductLoading(false);
  };
  const fetchBuyerTopProducts = async (customerId: string) => {
    setProductLoading(true);
    let apiService = new APICallService(
      buyer.getBuyerOrderedProduct,
      customerId,
      '',
      '',
      false,
      '',
      CustomNotificationConst
    );
    let response = await apiService.callAPI();
    if (response.length) {
      let data: any = [];
      response.map((val: any) => {
        data.push({
          value: val?.variant?._id,
          name: val?.variant?.title,
          title: val?.variant?.title,
          label: (
            <>
              <span className="symbol symbol-xl-40px symbol-35px border border-r10px me-2">
                <img
                  src={val?.variant?.media[0]?.url}
                  className="p-1"
                />
              </span>
              <span className="fs-16 fw-600 text-black ">
                {val?.variant?.title}
              </span>
            </>
          ),
          id: val?.variant?._id,
          productId: val?.variant?.product?.reference,
        });
      });
      setProductsList(data);
    } else {
      //  error('No products are available for selected customer.');
      setProductsList([]);
    }
    setProductLoading(false);
  };
  const handleInputChange = (event: any, name: string) => {
    const tempData: any = { ...notificationData };
    const tempValidation: any = { ...validation };
    tempData[name] = event.target.value;
    if (event.target.value.trim().length > 0) {
      tempValidation[name] = false;
    } else {
      tempValidation[name] = true;
    }
    if (name === 'title' && event.target.value.trim().length > 100) {
      tempValidation[name] = true;
    }
    if (name === 'message' && event.target.value.trim().length > 500) {
      tempValidation[name] = true;
    }
    setValidation(tempValidation);
    setNotificationData(tempData);
  };
  const handleCustomer = async (event: any) => {
    const tempData = { ...notificationData };
    if (Array.isArray(event)) {
      if (event.length > tempData.buyers.length) {
        if (
          event.some((item) => item.value === 0) ||
          event.length == customerList.length - 1
        ) {
          tempData.buyers = customerList;
        } else {
          tempData.buyers = event;
        }
      } else {
        if (event.some((val: any) => val.value === 0)) {
          let temp = event.filter((val: any) => val.value !== 0);
          tempData.buyers = temp;
        } else if (
          !event.some((val: any) => val.value === 0) &&
          event.length == customerList.length - 1
        ) {
          tempData.buyers = [];
        } else {
          tempData.buyers = event;
        }
      }
    } else {
      tempData.buyers = [event];
    }
    if (event && selectedNotification?.value == 3) {
      // await fetchBuyerCart(event.id);
    }
    if (event && event?.value && selectedNotification?.value === 2) {
      await fetchBuyerTopProducts(event?.value);
    }
    setNotificationData(tempData);
  };
  const handleProduct = async (event: any) => {
    const tempData = { ...notificationData };
    // if (Array.isArray(event)) {
    //   if (event.length > tempData.variants.length) {
    //     if (
    //       event.some((item) => item.value === 0) ||
    //       event.length == productsList.length - 1
    //     ) {
    //       tempData.variants = productsList;
    //     } else {
    //       tempData.variants = event;
    //     }
    //   } else {
    //     if (event.some((val: any) => val.value === 0)) {
    //       let temp = event.filter((val: any) => val.value !== 0);
    //       tempData.variants = temp;
    //     } else if (
    //       !event.some((val: any) => val.value === 0) &&
    //       event.length == productsList.length - 1
    //     ) {
    //       tempData.variants = [];
    //     } else {
    //       tempData.variants = event;
    //     }
    //   }
    // } else {
    //   tempData.variants = [event];
    // }
    if (event.length > 10) {
      return error(
        'Products limit exceeded, You can select only 10 products at a time.'
      );
    }
    tempData.variants = event;
    setNotificationData(tempData);
  };
  const handleNotification = async (event: any) => {
    const temp = { ...notificationData };
    temp.buyers = [];
    temp.variants = [];
    setProductsList([]);
    // setSelectedProduct([]);
    // setSelectedCustomer([]);
    setSelectedNotification(event);
    if (event.value == 4) {
      await fetchProducts();
    }
    if (event.value === 5) {
      await fetchTopProducts();
    }
    if (event.value === 6) {
      await fetchTopCustomers();
    }
    setNotificationData(temp);
  };
  const handleSubmit = async () => {
    const tempValidation: any = JSON.parse(JSON.stringify(validation));
    const tempData: any = JSON.parse(JSON.stringify(notificationData));
    tempData.title = tempData.title.trim();
    tempData.message = tempData.message.trim();
    if (tempData.title.length === 0 || tempData.title.length > 100) {
      tempValidation.title = true;
    }
    if (tempData.message.length === 0 || tempData.message.length > 500) {
      tempValidation.message = true;
    }
    setValidation(tempValidation);
    if (!tempValidation.title && !tempValidation.message) {
      if (!selectedNotification) {
        return error('Please select a notification type');
      }
      if (tempData.buyers.length === 0) {
        return error('Please select customers');
      }
      if (
        (selectedNotification.value == 2 ||
          selectedNotification.value == 5 ||
          selectedNotification.value == 4) &&
        tempData.variants.length === 0
      ) {
        return error('Please select products');
      }
      if (
        selectedNotification.value !== 2 &&
        tempData.buyers.some((val: any) => val.value == 0)
      ) {
        tempData.buyers.shift();
      }
      if (
        (selectedNotification.value == 2 ||
          selectedNotification.value == 5 ||
          selectedNotification.value == 4) &&
        tempData.variants.some((val: any) => val.value === 0)
      ) {
        tempData.variants.shift();
      }
      tempData.buyers = tempData.buyers.map((item: any) => item.value);
      tempData.variants = tempData.variants.map((item: any) => {
        return {
          variantId: item.value,
          productId: item.productId,
        };
      });
      tempData.type = selectedNotification.type;
      setLoading(true);
      const apiService = new APICallService(
        buyer.sendNotification,
        tempData,
        '',
        '',
        false,
        '',
        CustomNotificationConst
      );
      const response = await apiService.callAPI();
      if (response) {
        success(customNotificationsToast.notificationSent);
        tempData.title = '';
        tempData.message = '';
        tempData.buyers = [];
        tempData.variants = [];
        setNotificationData(tempData);
        setSelectedNotification(null);
      }
      setLoading(false);
    }
  };
  return (
    <div className="p-9">
      <Row className="g-6">
        <Col xs={12}>
          <h1 className="fs-22 fw-bolder mb-0">
            {CustomNotificationString.cutomNofication}
          </h1>
        </Col>
        <Col xs={12}>
          <Card className="bg-light border">
            <Card.Body className="p-9">
              <Row>
                <Col
                  xs={12}
                  className="mb-6 "
                >
                  <Row className="align-items-center">
                    <Col lg={2}>
                      <label
                        className="fs-16 fw-500 text-dark mb-lg-0 mb-3 required"
                        htmlFor="title"
                      >
                        {CustomNotificationString.title}
                      </label>
                    </Col>
                    <Col lg={5}>
                      <input
                        id="title"
                        type="text"
                        required
                        className={clsx(
                          'form-control form-control-solid bg-white h-60px  form-control-lg text-dark fs-16 fw-600',
                          validation.title ? 'border-danger' : 'border-gray-300'
                        )}
                        name="title"
                        placeholder="Enter title"
                        value={notificationData.title}
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => handleInputChange(e, 'title')}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col
                  lg={12}
                  className="mb-6"
                >
                  <Row className="align-items-center ">
                    <Col
                      lg={2}
                      className="d-flex align-items-start"
                    >
                      <label className="fs-16 fw-500 text-dark required">
                        {CustomNotificationString.description}
                      </label>
                    </Col>
                    <Col lg={5}>
                      <textarea
                        className={clsx(
                          'form-control form-control-solid bg-white  form-control-lg text-dark fs-16 fw-600',
                          validation.message
                            ? 'border-danger'
                            : 'border-gray-300'
                        )}
                        rows={3}
                        name="message"
                        placeholder="Enter description"
                        value={notificationData.message}
                        onChange={(e: any) => {
                          handleInputChange(e, 'message');
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col
                  lg={12}
                  className="mb-6"
                >
                  <Row className="align-items-center ">
                    <Col
                      lg={2}
                      className="d-flex align-items-start"
                    >
                      <label className="fs-16 fw-500 text-dark required">
                        Send notification for
                      </label>
                    </Col>
                    <Col lg={5}>
                      <CustomSelectWhite
                        // border={
                        //   validation[index].primaryCategory ? '#e55451' : ''
                        // }
                        // disabled={loading}
                        // isLoading={fetchLoader}
                        placeholder="Select notification type"
                        options={notificationJSON}
                        loadingMessage={'Fetching Data'}
                        isMulti={false}
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): any => handleNotification(e)}
                        value={selectedNotification}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col
                  lg={12}
                  className="mb-6"
                >
                  <Row className="align-items-center ">
                    <Col
                      lg={2}
                      className="d-flex align-items-start"
                    >
                      <label className="fs-16 fw-500 text-dark required">
                        Select Customer
                      </label>
                    </Col>
                    <Col lg={5}>
                      {/* <CustomReportSelect
                        backgroundColor="#ffff"
                        minHeight="60px"
                        closeMenuOnSelect={false}
                        isSearchable={true}
                        options={customerList}
                        text={'customer selected'}
                        hideSelectedOptions={false}
                        onChange={(event: any) => {
                          handleCustomer(event);
                        }}
                        value={notificationData.buyers}
                        isMulti={
                          selectedNotification
                            ? selectedNotification.value == 2 ||
                              selectedNotification.value == 3
                              ? false
                              : true
                            : true
                        }
                      />
                       */}
                      <CustomReportSelect
                        backgroundColor="#ffff"
                        className={
                          validation.buyers
                            ? 'border border-danger rounded'
                            : ''
                        }
                        minHeight="60px"
                        closeMenuOnSelect={false}
                        isSearchable={true}
                        placeholder="Select customers"
                        // options={customerList}
                        options={
                          selectedNotification
                            ? selectedNotification.value == 2
                              ? customerList.filter(
                                  (val: any) => val.value !== 0
                                )
                              : customerList
                            : customerList
                        }
                        text={'customer selected'}
                        hideSelectedOptions={false}
                        onChange={(event: any) => {
                          handleCustomer(event);
                        }}
                        isLoading={customerLoading}
                        isDisabled={customerLoading}
                        value={
                          notificationData.buyers.length
                            ? notificationData.buyers
                            : []
                        }
                        defaultValue={
                          notificationData.buyers.length
                            ? notificationData.buyers
                            : []
                        }
                        isMulti={
                          selectedNotification
                            ? selectedNotification.value == 2
                              ? false
                              : true
                            : true
                        }
                      />
                    </Col>
                  </Row>
                </Col>
                {selectedNotification &&
                selectedNotification.value != 1 &&
                selectedNotification.value != 3 &&
                productsList.length ? (
                  <Col
                    lg={12}
                    className="mb-6"
                  >
                    <Row className="align-items-center ">
                      <Col
                        lg={2}
                        className="d-flex align-items-start"
                      >
                        <label className="fs-16 fw-500 text-dark required">
                          Select Product
                        </label>
                      </Col>
                      <Col lg={5}>
                        <CustomReportSelect
                          backgroundColor="#ffff"
                          minHeight="60px"
                          closeMenuOnSelect={false}
                          isSearchable={true}
                          placeholder="Select products"
                          options={productsList}
                          text={'products selected'}
                          hideSelectedOptions={false}
                          onChange={(event: any) => {
                            handleProduct(event);
                          }}
                          value={notificationData.variants}
                          isMulti={true}
                        />
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          {Method.hasPermission(CustomNotificationConst, Add, currentUser) ? (
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading && (
                <span
                  className="indicator-progress fs-16 fw-bold"
                  style={{ display: 'block' }}
                >
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
              {!loading && <span className="indicator-label">Submit</span>}
            </Button>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </div>
  );
};
export default CustomNotification;
